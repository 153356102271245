import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import './FireParticles.css';

const FireParticles = () => {
    const containerRef = useRef();
    const sceneRef = useRef();
    const cameraRef = useRef();
    const rendererRef = useRef();
    const particlesRef = useRef();

    useEffect(() => {
        const container = containerRef.current;

        sceneRef.current = new THREE.Scene();

        cameraRef.current = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
        );
        cameraRef.current.position.z = 5;

        rendererRef.current = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true
        });
        rendererRef.current.setSize(window.innerWidth, window.innerHeight);
        rendererRef.current.setPixelRatio(window.devicePixelRatio);
        rendererRef.current.setClearColor(0x000000, 0);
        if (container) {
            container.appendChild(rendererRef.current.domElement);
        }

        const particlesGeometry = new THREE.BufferGeometry();
        const particleCount = 500;
        const positions = new Float32Array(particleCount * 3);
        const velocities = new Float32Array(particleCount * 3);
        const colors = new Float32Array(particleCount * 3);

        for (let i = 0; i < particleCount * 3; i += 3) {
            positions[i] = (Math.random() - 0.5) * 10;
            positions[i + 1] = (Math.random() - 0.5) * 10;
            positions[i + 2] = (Math.random() - 0.5) * 10;


            velocities[i] = (Math.random() - 0.5) * 0.001;
            velocities[i + 1] = (Math.random() - 0.5) * 0.001;
            velocities[i + 2] = (Math.random() - 0.5) * 0.001;

            const color = new THREE.Color(0xe6ff00);
            colors[i] = color.r;
            colors[i + 1] = color.g;
            colors[i + 2] = color.b;
        }

        particlesGeometry.setAttribute(
            'position',
            new THREE.BufferAttribute(positions, 3)
        );
        particlesGeometry.setAttribute(
            'color',
            new THREE.BufferAttribute(colors, 3)
        );

        const particlesMaterial = new THREE.PointsMaterial({
            size: 0.01,
            transparent: true,
            opacity: 0.6,
            vertexColors: true,
            blending: THREE.AdditiveBlending,
            sizeAttenuation: true
        });

        particlesRef.current = new THREE.Points(particlesGeometry, particlesMaterial);
        sceneRef.current.add(particlesRef.current);

        const animate = () => {
            requestAnimationFrame(animate);

            const positions = particlesRef.current.geometry.attributes.position.array;

            for (let i = 0; i < positions.length; i += 3) {
                positions[i] += velocities[i];
                positions[i + 1] += velocities[i + 1];
                positions[i + 2] += velocities[i + 2];

                if (Math.abs(positions[i]) > 5) {
                    positions[i] = (Math.random() - 0.5) * 10;
                    velocities[i] = (Math.random() - 0.5) * 0.001;
                }
                if (Math.abs(positions[i + 1]) > 5) {
                    positions[i + 1] = (Math.random() - 0.5) * 10;
                    velocities[i + 1] = (Math.random() - 0.5) * 0.001;
                }
                if (Math.abs(positions[i + 2]) > 5) {
                    positions[i + 2] = (Math.random() - 0.5) * 10;
                    velocities[i + 2] = (Math.random() - 0.5) * 0.001;
                }
            }

            particlesRef.current.geometry.attributes.position.needsUpdate = true;
            particlesRef.current.rotation.x += 0.00005;
            particlesRef.current.rotation.y += 0.0001;

            rendererRef.current.render(sceneRef.current, cameraRef.current);
        };

        animate();

        const handleResize = () => {
            cameraRef.current.aspect = window.innerWidth / window.innerHeight;
            cameraRef.current.updateProjectionMatrix();
            rendererRef.current.setSize(window.innerWidth, window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (container) {
                container.removeChild(rendererRef.current.domElement);
            }
        };
    }, []);

    return <div ref={containerRef} className="particles-container" />;
};

export default FireParticles;
