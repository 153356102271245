
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/global.css';
import Home from "./pages/Home";
import Footer from "./pages/Footer";
import NavBar from "./pages/NavBar";
import ResourcePack from "./pages/ResourcePack";
import JarDecompiler from "./pages/JarDecompiler";
import ServerInfo from "./pages/ServerInfo";
import Download from "./pages/Download";
import PlayerTracker from "./pages/PlayerTracker";
import Shop from "./pages/Shop";
import Error404 from "./pages/Error404";
import ScrollToTop from "./components/ScrollToTop";
import ScrollToTopButton from "./components/ScrollToTopButton";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import FireParticles from "./components/FireParticles";

const App = () => {
  return (
      <Router>
        <div className="app">
            <FireParticles />
            <NavBar />
            <ScrollToTop />
            <ScrollToTopButton />
          <Routes>
            <Route path="/" element={<Home />} />
              <Route path="resourcepack" element={<ResourcePack />} />
              <Route path="jardecompiler" element={<JarDecompiler />} />
              <Route path="server-info" element={<ServerInfo />} />
              <Route path="download" element={<Download />} />
              <Route path="player-tracker" element={<PlayerTracker />} />
              <Route path="shop" element={<Shop />} />
              <Route path="*" element={<Error404 />} />
              <Route path="terms" element={<TermsOfService />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
          </Routes>
          <Footer />
        </div>
      </Router>
  );
};

export default App;