import React, { useState } from "react";
import { FaServer, FaUsers, FaGlobe, FaCode, FaCheckCircle, FaJava, FaGamepad } from "react-icons/fa";
import '../styles/ServerInfo.css';

const ServerInfo = () => {
    const [serverAddress, setServerAddress] = useState("");
    const [serverType, setServerType] = useState("java");
    const [isLoading, setIsLoading] = useState(false);
    const [serverData, setServerData] = useState(null);
    const [error, setError] = useState(null);
    const [isPageUnavailable] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        setServerData(null);

        setTimeout(() => {
            if (serverType === "java") {
                setServerData({
                    status: "online",
                    players: {
                        online: 150,
                        max: 1000
                    },
                    version: "1.20.4",
                    description: "✨ Example Minecraft Server ✨",
                    favicon: "data:image/png;base64,<base64_data>",
                    ping: 42,
                    plugins: ["EssentialsX", "WorldEdit", "Vault"],
                    mods: ["OptiFine", "JourneyMap"],
                });
            } else {
                setServerData({
                    status: "online",
                    players: {
                        online: 85,
                        max: 500
                    },
                    version: "1.20.10",
                    description: "Bedrock Edition Server",
                    gameMode: "survival",
                    port: 19132,
                });
            }
            setIsLoading(false);
        }, 1000);
    };

    if (isPageUnavailable) {
        return (
            <div className="unavailable-container">
                <h1 className="unavailable-title">Page Unavailable</h1>
                <p className="unavailable-message">
                    Our Server Tracker tool is currently under development. We're working hard to deliver the best
                    experience possible.
                </p>
                <p className="unavailable-message">
                    Please check back soon for updates and new features!
                </p>
            </div>
        );
    }

    return (
        <>
            <section className="hero">
                <div className="hero-content">
                    <div className="hero-eyebrow">
                        <span className="status-indicator"></span>
                        Server Intelligence Tool
                    </div>
                    <h1>
                        <span className="gradient-text">Server Info</span>
                    </h1>
                    <p className="hero-description">
                        Get detailed information about any Minecraft server. Support for both Java and Bedrock editions.
                    </p>
                </div>
            </section>

            <section className="server-lookup">
                <div className="lookup-container">
                    <div className="server-type-toggle">
                        <button
                            className={`type-btn ${serverType === 'java' ? 'active' : ''}`}
                            onClick={() => setServerType('java')}
                        >
                            <FaJava /> Java Edition
                        </button>
                        <button
                            className={`type-btn ${serverType === 'bedrock' ? 'active' : ''}`}
                            onClick={() => setServerType('bedrock')}
                        >
                            <FaGamepad /> Bedrock Edition
                        </button>
                    </div>

                    <form onSubmit={handleSubmit} className="lookup-form">
                        <input
                            type="text"
                            placeholder={`Enter ${serverType === 'java' ? 'Java' : 'Bedrock'} server address...`}
                            value={serverAddress}
                            onChange={(e) => setServerAddress(e.target.value)}
                            className="server-input"
                        />
                        <button type="submit" className="lookup-btn" disabled={isLoading}>
                            {isLoading ? "Checking..." : "Check Server"}
                        </button>
                    </form>

                    {error && (
                        <div className="error-message">
                            {error}
                        </div>
                    )}

                    {serverData && (
                        <div className="server-info-grid">
                            <div className="info-card">
                                <div className="info-icon">
                                    <FaServer />
                                </div>
                                <h3>Status</h3>
                                <div className={`status-badge ${serverData.status}`}>
                                    {serverData.status.toUpperCase()}
                                </div>
                            </div>

                            <div className="info-card">
                                <div className="info-icon">
                                    <FaUsers />
                                </div>
                                <h3>Players</h3>
                                <p className="info-value">{serverData.players.online} / {serverData.players.max}</p>
                            </div>

                            <div className="info-card">
                                <div className="info-icon">
                                    <FaCode />
                                </div>
                                <h3>Version</h3>
                                <p className="info-value">{serverData.version}</p>
                            </div>

                            {serverType === 'java' && serverData.ping && (
                                <div className="info-card">
                                    <div className="info-icon">
                                        <FaGlobe />
                                    </div>
                                    <h3>Ping</h3>
                                    <p className="info-value">{serverData.ping}ms</p>
                                </div>
                            )}

                            {serverType === 'bedrock' && serverData.gameMode && (
                                <div className="info-card">
                                    <div className="info-icon">
                                        <FaGamepad />
                                    </div>
                                    <h3>Game Mode</h3>
                                    <p className="info-value">{serverData.gameMode}</p>
                                </div>
                            )}
                        </div>
                    )}

                    {serverData && serverType === 'java' && (serverData.plugins || serverData.mods) && (
                        <div className="additional-info">
                            {serverData.plugins && (
                                <div className="plugins-section">
                                    <h3>Detected Plugins</h3>
                                    <div className="tags-grid">
                                        {serverData.plugins.map((plugin, index) => (
                                            <span key={index} className="tag">
                                                <FaCheckCircle /> {plugin}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {serverData.mods && (
                                <div className="mods-section">
                                    <h3>Detected Mods</h3>
                                    <div className="tags-grid">
                                        {serverData.mods.map((mod, index) => (
                                            <span key={index} className="tag">
                                                <FaCheckCircle /> {mod}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default ServerInfo;