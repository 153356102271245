import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBook, FaCog, FaSearch, FaDownload, FaServer } from "react-icons/fa";
import { FaShop } from "react-icons/fa6";
import "../styles/NavBar.css";
import LogoImage from "../assets/logo512.png";

const NavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <nav>
            <div className="nav-container">
                <div className="nav-brand">
                    <NavLink to="/" className="logo-nav">
                        <img src={LogoImage} alt="ResourceDecrypt Logo" className="logo-png" />
                        <span className="brand-name">ResourceDecrypt</span>
                    </NavLink>
                </div>

                <div className={`nav-right ${isMenuOpen ? "active" : ""}`}>
                    <div className="nav-links">
                        <NavLink to="/resourcepack" className="nav-link">
              <span className="nav-icon">
                <FaBook />
              </span>
                            <span>Resource Packs</span>
                        </NavLink>
                        <NavLink to="/jardecompiler" className="nav-link">
              <span className="nav-icon">
                <FaCog />
              </span>
                            <span>JAR Files</span>
                        </NavLink>
                        <NavLink to="/player-tracker" className="nav-link">
              <span className="nav-icon">
                <FaSearch />
              </span>
                            <span>Player Tracker</span>
                        </NavLink>
                        <NavLink to="/server-info" className="nav-link">
              <span className="nav-icon">
                <FaServer />
              </span>
                            <span>Server Tracker</span>
                        </NavLink>
                        <NavLink to="/shop" className="nav-link" end>
              <span className="nav-icon">
                <FaShop />
              </span>
                            <span>Shop</span>
                        </NavLink>
                    </div>

                    <div className="nav-actions">
                        <NavLink to="/download" className="nav-btn primary">
                            <FaDownload />
                            <span>Download</span>
                        </NavLink>
                    </div>


                    <div className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;