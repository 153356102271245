import React from "react";
import { Link } from "react-router-dom";
import { FaHome, FaDownload, FaDiscord } from "react-icons/fa";
import "../styles/Error404.css";

const Error404 = () => {
    return (
        <>
            <section className="hero">
                <div className="hero-content">
                    <div className="hero-eyebrow">
                        <span className="status-indicator"></span>
                        Oops, Something Went Wrong
                    </div>
                    <h1>
                        <span className="gradient-text">404</span>
                    </h1>
                    <p className="hero-description">
                        The page you’re looking for doesn’t exist or got lost in the void. Let’s get you back on track.
                    </p>

                    <div className="error-navigation">
                        <Link to="/" className="nav-button">
                            <FaHome className="nav-icon" />
                            <span>Home</span>
                        </Link>
                        <Link to="/download" className="nav-button">
                            <FaDownload className="nav-icon" />
                            <span>Download</span>
                        </Link>
                        <a
                            href="https://discord.gg/example"
                            className="nav-button"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaDiscord className="nav-icon" />
                            <span>Discord</span>
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Error404;