import React from "react";
import { FaDiscord, FaGithub } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../styles/Footer.css";

const Footer = () => {
    return (
        <footer>
            <div className="footer-content">
                <div className="footer-group">
                    <h4>Quick Links</h4>
                    <div className="footer-links">
                        <Link to="/resourcepack">Resource Packs</Link>
                        <Link to="/jardecompiler">JAR Files</Link>
                        <Link to="/tools/player-tracker">Player Tracker</Link>
                        <Link to="/server-info">Server Info</Link>
                    </div>
                </div>

                <div className="footer-brand">
                    <div className="footer-logo">
                        <span className="footer-brand-name">ResourceDecrypt</span>
                    </div>
                    <p className="footer-description">
                        Advanced Minecraft analysis tools for resource packs, server
                        intelligence, and player tracking.
                    </p>
                    <div className="social-links">
                        <a
                            href="https://discord.gg/b2AUPaj"
                            className="social-link discord"
                        >
                            <FaDiscord />
                        </a>
                        <a href="https://github.com/Diegoysp/" className="social-link github">
                            <FaGithub />
                        </a>
                    </div>
                </div>

                <div className="footer-group">
                    <h4>Support</h4>
                    <div className="footer-links">
                        <Link to="/shop">Donate</Link>
                        <Link to="https://discord.gg/b2AUPaj">Discord</Link>
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/terms">Terms of Service</Link>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="footer-bottom-content">
                    <p className="copyright">
                        © {new Date().getFullYear()} ResourceDecrypt. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;