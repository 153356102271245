import React, { useState } from "react";
import { FaUser, FaServer, FaClock, FaCalendar, FaGamepad, FaSearch, FaFingerprint } from "react-icons/fa";
import '../styles/PlayerTracker.css';

const PlayerTracker = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchType, setSearchType] = useState("username");
    const [isLoading, setIsLoading] = useState(false);
    const [playerData, setPlayerData] = useState(null);
    const [error, setError] = useState(null);
    const [isPageUnavailable] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        setPlayerData(null);

        setTimeout(() => {
            setPlayerData({
                username: "Example_Player",
                uuid: "550e8400-e29b-41d4-a716-446655440000",
                lastSeen: "2024-02-28T15:30:00",
                firstSeen: "2023-01-15",
                avatar: "/api/placeholder/64/64",
                serverHistory: [
                    {
                        server: "play.example.net",
                        lastJoin: "2024-02-28T15:30:00",
                        totalTime: "124h 30m",
                        visits: 45,
                        type: "Survival",
                    },
                    {
                        server: "minigames.example.com",
                        lastJoin: "2024-02-27T18:45:00",
                        totalTime: "56h 15m",
                        visits: 28,
                        type: "Minigames",
                    },
                    {
                        server: "creative.example.org",
                        lastJoin: "2024-02-25T12:20:00",
                        totalTime: "89h 45m",
                        visits: 34,
                        type: "Creative",
                    }
                ]
            });
            setIsLoading(false);
        }, 1000);
    };

    if (isPageUnavailable) {
        return (
            <div className="unavailable-container">
                <h1 className="unavailable-title">Page Unavailable</h1>
                <p className="unavailable-message">
                    Our Player Tracker tool is currently under development. We're working hard to deliver the best
                    experience possible.
                </p>
                <p className="unavailable-message">
                    Please check back soon for updates and new features!
                </p>
            </div>
        );
    }

    return (
        <>
            <section className="hero">
                <div className="hero-content">
                    <div className="hero-eyebrow">
                        <span className="status-indicator"></span>
                        Player History Tracker
                    </div>
                    <h1>
                        <span className="gradient-text">Player Tracker</span>
                    </h1>
                    <p className="hero-description">
                        Track player activity across Minecraft servers. Enter a username or UUID to get started.
                    </p>
                </div>
            </section>

            <section className="tracker-section">
                <div className="tracker-container">
                    <div className="search-container">
                        <div className="search-type-toggle">
                            <button
                                className={`type-btn ${searchType === 'username' ? 'active' : ''}`}
                                onClick={() => setSearchType('username')}
                            >
                                <FaUser /> Username
                            </button>
                            <button
                                className={`type-btn ${searchType === 'uuid' ? 'active' : ''}`}
                                onClick={() => setSearchType('uuid')}
                            >
                                <FaFingerprint /> UUID
                            </button>
                        </div>

                        <form onSubmit={handleSubmit} className="search-form">
                            <input
                                type="text"
                                placeholder={`Enter player ${searchType}...`}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="search-input"
                            />
                            <button type="submit" className="search-btn" disabled={isLoading}>
                                <FaSearch />
                                {isLoading ? "Searching..." : "Search"}
                            </button>
                        </form>
                    </div>

                    {error && (
                        <div className="error-message">
                            {error}
                        </div>
                    )}

                    {playerData && (
                        <div className="player-info-container">
                            <div className="player-header">
                                <div className="player-avatar">
                                    <img src={playerData.avatar} alt={playerData.username} />
                                </div>
                                <div className="player-details">
                                    <h2>{playerData.username}</h2>
                                    <p className="uuid-text">{playerData.uuid}</p>
                                    <div className="player-stats">
                                        <span><FaCalendar /> First seen: {new Date(playerData.firstSeen).toLocaleDateString()}</span>
                                        <span><FaClock /> Last active: {new Date(playerData.lastSeen).toLocaleString()}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="server-history">
                                <h3>Server History</h3>
                                <div className="server-grid">
                                    {playerData.serverHistory.map((server, index) => (
                                        <div key={index} className="server-card">
                                            <div className="server-card-header">
                                                <FaServer className="server-icon" />
                                                <h4>{server.server}</h4>
                                                <span className="server-type">{server.type}</span>
                                            </div>
                                            <div className="server-stats">
                                                <div className="stat-item">
                                                    <FaClock />
                                                    <span>Total Time: {server.totalTime}</span>
                                                </div>
                                                <div className="stat-item">
                                                    <FaGamepad />
                                                    <span>Visits: {server.visits}</span>
                                                </div>
                                                <div className="stat-item">
                                                    <FaCalendar />
                                                    <span>Last Join: {new Date(server.lastJoin).toLocaleString()}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default PlayerTracker;
