import React from "react";
import "../styles/Home.css";
import {FaBox, FaGlobe, FaUser} from "react-icons/fa6";
import {FaCheckCircle, FaHeadset, FaSearch, FaUsers} from "react-icons/fa";

const Home = () => {
    return (
        <>
            <section className="hero">
                <div>
                    <div className="hero-eyebrow">
                        <span className="status-indicator"></span>
                        Minecraft Analysis Tools
                    </div>
                    <h1>
                        <span className="gradient-text">ResourceDecrypt</span>
                    </h1>
                    <p className="hero-description">
                        Professional toolkit for Minecraft resource analysis. Unpack resource packs,
                        analyze JAR files, track players, and gather server intelligence.
                    </p>
                </div>
            </section>

            <section className="tools-showcase" id="tools">
                <div className="tool-card">
                    <div className="tool-icon2">
                        <FaBox/>
                    </div>
                    <h3>Resource Pack Analysis</h3>
                    <p>Decompile and analyze Minecraft resource packs with texture extraction.</p>
                </div>

                <div className="tool-card">
                    <div className="tool-icon2">
                        <FaSearch/>
                    </div>
                    <h3>JAR Inspector</h3>
                    <p>Deep analysis of Minecraft JAR files with security scanning.</p>
                </div>

                <div className="tool-card">
                    <div className="tool-icon2">
                        <FaUser/>
                    </div>
                    <h3>Player History</h3>
                    <p>Track player activity across servers using nickname or UUID.</p>
                </div>

                <div className="tool-card">
                    <div className="tool-icon2">
                        <FaGlobe/>
                    </div>
                    <h3>Server Intelligence</h3>
                    <p>Gather detailed information about Minecraft servers.</p>
                </div>
            </section>

            <section className="stats">
                <div className="stats-container">
                    <div className="stat-item">
                        <div className="stat-icon"><FaUsers/></div>
                        <div className="stat-number">3K+</div>
                        <p>Daily Users</p>
                    </div>
                    <div className="stat-item">
                        <div className="stat-icon"><FaSearch/></div>
                        <div className="stat-number">2300+</div>
                        <p>Scans Performed</p>
                    </div>
                    <div className="stat-item">
                        <div className="stat-icon"><FaCheckCircle/></div>
                        <div className="stat-number">99.9%</div>
                        <p>Accuracy Rate</p>
                    </div>
                    <div className="stat-item">
                        <div className="stat-icon"><FaHeadset/></div>
                        <div className="stat-number">24/7</div>
                        <p>Support</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
