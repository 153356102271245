import React from "react";
import "../styles/Policy.css";

const privacyRules = [
    {
        id: 1,
        title: "No Data Collection",
        description:
            "We don’t collect any information about our users. We don’t track your IP address, require registration, use cookies, or employ any form of identification. Any technical data processed (e.g., for site functionality) is temporary, not stored, and not our responsibility. You remain completely anonymous to us."
    },
    {
        id: 2,
        title: "File Handling",
        description:
            "Our site is a tool for decompiling encrypted ZIP and JAR files. All files you upload are automatically deleted from our server 2 hours after being uploaded. We don’t store them longer, analyze them, or use them in any way."
    },
    {
        id: 3,
        title: "Your Responsibility",
        description:
            "You decide what you upload to our site. We have no control over the content of the files, don’t monitor them, and take no responsibility for their nature, legality, or any consequences. The site owner is not liable for any misuse of this tool or violations of law caused by your actions."
    },
    {
        id: 4,
        title: "Contact",
        description:
            "If you have questions about this policy, feel free to reach out to us at [insert contact method, e.g., email, if desired]. We’ll respond as soon as possible."
    },
    {
        id: 5,
        title: "Changes to This Policy",
        description:
            "We reserve the right to update this policy at any time. If changes occur, the updated version will be posted on this page."
    },
    {
        id: 6,
        title: "No Server Logs",
        description:
            "We don’t keep logs of your activity on our site. No records of your visits, uploads, or interactions are stored. The owner is not liable for any claims related to data that we do not collect or control."
    },
    {
        id: 7,
        title: "Third-Party Risks",
        description:
            "We are not responsible for any risks or issues caused by third parties (e.g., your internet provider, device, or external entities) in connection with your use of this site. The owner bears no liability for such matters."
    }
];

const PrivacyPolicy = () => {
    return (
        <section className="policy-page">
            <section className="policy-hero">
                <div>
                    <div className="policy-eyebrow">
                        <span className="status-indicator"></span>
                        Your Privacy Matters
                    </div>
                    <h1>
                        <span className="gradient-text">Privacy Policy</span>
                    </h1>
                    <p className="policy-description">
                        At resourcedecrypt.cc, we keep it simple: no data, no tracking, just a tool for you to use.
                    </p>
                </div>
            </section>

            <section className="policy-list">
                <div className="policy-container">
                    {privacyRules.map((rule) => (
                        <div key={rule.id} className="policy-item">
                            <div className="policy-number">{rule.id}</div>
                            <div className="policy-content">
                                <h3>{rule.title}</h3>
                                <p>{rule.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </section>
    );
};

export default PrivacyPolicy;