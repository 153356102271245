import React from "react";
import {FaBitcoin, FaEthereum, FaCopy, FaSun} from "react-icons/fa";
import { SiLitecoin, SiMonero } from "react-icons/si";
import "../styles/Shop.css";

const Shop = () => {
    const products = [
        { id: "plan1", title: "1 Month License", price: 9.99, duration: "30 Days", stock: 0, sellixId: "65c9216b361b5" },
        { id: "plan2", title: "6 Month License", price: 49.99, duration: "6 Months", stock: 0, sellixId: "65c9216b361b5" },
        { id: "plan3", title: "Lifetime License", price: 199.99, duration: "Lifetime", stock: 0, sellixId: "65c9216b361b5" },
    ];

    const donations = [
        { crypto: "Bitcoin (BTC)", address: "bc1q4ld9nzmgnn8hvq8jtvf58tlytnwdpden7x458r", icon: <FaBitcoin className="crypto-icon bitcoin" /> },
        { crypto: "Ethereum (ETH Network)", address: "0x5b843C4e9Bc24d4A0c58506fd7dE1B3cf6480893", icon: <FaEthereum className="crypto-icon ethereum" /> },
        { crypto: "USDT (ETH Network)", address: "0x5b843C4e9Bc24d4A0c58506fd7dE1B3cf6480893", icon: <FaEthereum className="crypto-icon tether" /> },
        { crypto: "Litecoin (LTC Network)", address: "LXn4xojed4D3WwLeHbRwtecWGnUXXMq7Mx", icon: <SiLitecoin className="crypto-icon litecoin" /> },
        { crypto: "Solana (SOL Network)", address: "4dDYPUf46LkKmqmyvK5yq9183QVi3FvuDMbzWzgqnEVD", icon: <FaSun className="crypto-icon solana" /> },
    ];

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert("Address copied to clipboard!");
        }).catch((err) => {
            console.error("Failed to copy: ", err);
        });
    };

    return (
        <>
            <section className="hero">
                <div className="hero-content">
                    <div className="hero-eyebrow">
                        <span className="status-indicator"></span>
                        Premium Access
                    </div>
                    <h1>
                        <span className="gradient-text">Choose Your License</span>
                    </h1>
                    <p className="hero-description">
                        Get full access to all ResourceDecrypt features with our flexible licensing options.
                    </p>
                </div>
            </section>

            <div className="shop-container">
                <div className="license-grid">
                    {products.map((product) => (
                        <div key={product.id} className="license-card">
                            <h2>{product.duration}</h2>
                            <p className="price">${product.price}</p>
                            {product.stock > 0 ? (
                                <button
                                >
                                    Purchase Now
                                </button>
                            ) : (
                                <button className="select-btn out-of-stock" disabled>
                                    Out of Stock
                                </button>
                            )}
                        </div>
                    ))}
                </div>

                <div className="payment-info">
                    <div className="accepted-payments">
                        <h3>We Accept</h3>
                        <div className="crypto-icons">
                            <FaBitcoin />
                            <FaEthereum />
                            <SiLitecoin />
                            <SiMonero />
                        </div>
                    </div>
                </div>

                <div className="donate-section">
                    <h3 className="donate-title">Support Us with a Donation</h3>
                    <p className="donate-description">
                        Thank you for considering supporting our project! You can donate using these cryptocurrency addresses:
                    </p>
                    <div className="crypto-list">
                        {donations.map((donation, index) => (
                            <div key={index} className="crypto-item">
                                <div className="crypto-header">
                                    {donation.icon}
                                    <span>{donation.crypto}</span>
                                </div>
                                <div className="crypto-address">
                                    <code>{donation.address}</code>
                                    <button
                                        className="copy-button"
                                        onClick={() => copyToClipboard(donation.address)}
                                    >
                                        <FaCopy />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Shop;