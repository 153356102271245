import React, { useState } from 'react';
import { FaUpload, FaTrash, FaFile, FaExclamationTriangle } from 'react-icons/fa';
import '../styles/JarDecompiler.css';

const JarDecompiler = () => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [notification, setNotification] = useState(null);
    const [isPageUnavailable] = useState(true);

    const MAX_FILE_SIZE = 10 * 1024 * 1024;

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFile(e.dataTransfer.files[0]);
    };

    const handleFile = (file) => {
        setFile(file);
        setError(file.size > MAX_FILE_SIZE);
    };

    const handleClear = () => {
        setFile(null);
        setError(false);
        setProgress(0);
        setIsUploading(false);
    };

    const handleUpload = () => {
        setIsUploading(true);
        setProgress(0);
        const interval = setInterval(() => {
            if (progress >= 100) {
                clearInterval(interval);
                setIsUploading(false);
                showNotification('Upload complete!', 'success');
            } else {
                setProgress(progress + 1);
            }
        }, 50);
    };

    const showNotification = (message, type) => {
        setNotification({ message, type });
        setTimeout(() => {
            setNotification(null);
        }, 3000);
    };

    if (isPageUnavailable) {
        return (
            <div className="unavailable-container">
                <h1 className="unavailable-title">Page Unavailable</h1>
                <p className="unavailable-message">
                    Our JAR Decompiler tool is currently under development. We're working hard to deliver the best
                    experience possible.
                </p>
                <p className="unavailable-message">
                    Please check back soon for updates and new features!
                </p>
            </div>
        );
    }

    return (
        <div className="jar-decompiler-container">
            {notification && (
                <div className={`notification ${notification.type}`}>
                    {notification.message}
                    <FaTrash className="close-icon" onClick={() => setNotification(null)} />
                </div>
            )}
            <div className="hero">
                <div className="hero-eyebrow2">
                    <span className="status-indicator" />
                    JAR File Decompiler
                </div>
                <h1 className="gradient-text">Decompiler</h1>
                <p className="hero-description">
                    Upload your JAR file for decompilation. Maximum supported file size: 10MB.
                </p>
            </div>

            <div className="content-section">
                <div className="upload-section">
                    <h2>Upload JAR File</h2>
                    <div
                        className="upload-area"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                        onClick={() => document.getElementById('fileInput').click()}
                    >
                        <FaUpload className="upload-icon" />
                        <p>Drag & drop JAR file here or click to browse</p>
                        <input
                            type="file"
                            id="fileInput"
                            accept=".jar"
                            onChange={(e) => handleFile(e.target.files[0])}
                            hidden
                        />
                    </div>
                    {file && (
                        <div className="file-info">
                            <FaFile />
                            <span>{file.name}</span>
                            <span>{formatFileSize(file.size)}</span>
                        </div>
                    )}
                    {error && (
                        <p className="file-error">
                            <FaExclamationTriangle /> File exceeds size limit
                        </p>
                    )}
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${progress}%` }}>
                            {isUploading && <span className="progress-text">{progress}%</span>}
                        </div>
                    </div>
                    <div className="action-buttons">
                        <button className="btn btn-secondary" onClick={handleClear} disabled={!file}>
                            <FaTrash /> Clear
                        </button>
                        <button className="btn btn-primary" onClick={handleUpload} disabled={!file || error}>
                            <FaUpload /> Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JarDecompiler;