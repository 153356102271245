import React, { useState } from "react";
import { FaDownload, FaShieldAlt, FaCode, FaCog } from "react-icons/fa";
import "../styles/Download.css";

const Download = () => {
    const [isDownloadAvailable] = useState(false);

    const handleDownload = () => {
        if (isDownloadAvailable) {
            window.open("https://example.com/ResourceDecrypt.jar", "_blank");
        }
    };

    return (
        <>
            <section className="hero">
                <div className="hero-content">
                    <div className="hero-eyebrow">
                        <span className="status-indicator"></span>
                        Latest Version: 1.2.5
                    </div>
                    <h1>
                        Download <span className="gradient-text">ResourceDecrypt</span>
                    </h1>
                    <p className="hero-description">
                        Get access to professional Minecraft analysis tools. Compatible with Minecraft 1.16.5 - 1.20.4
                    </p>
                    <div className="download-buttons">
                        <button
                            className={`download-btn primary ${!isDownloadAvailable ? "disabled" : ""}`}
                            onClick={handleDownload}
                            disabled={!isDownloadAvailable}
                        >
                            <FaDownload />
                            {isDownloadAvailable ? "Download For Java Minecraft" : "In Development"}
                        </button>
                    </div>
                </div>
            </section>

            <section className="features-grid">
                <div className="feature-card">
                    <div className="feature-icon">
                        <FaShieldAlt />
                    </div>
                    <h3>Secure & Safe</h3>
                    <p>Fully verified and safe to use. Regular security updates and monitoring.</p>
                </div>

                <div className="feature-card">
                    <div className="feature-icon">
                        <FaCode />
                    </div>
                    <h3>Open Source</h3>
                    <p>Transparent codebase available for review on GitHub.</p>
                </div>

                <div className="feature-card">
                    <div className="feature-icon">
                        <FaCog />
                    </div>
                    <h3>Easy Setup</h3>
                    <p>Simple installation process. Just drop into your mods folder.</p>
                </div>
            </section>

            <section className="installation">
                <div className="installation-container">
                    <h2>Installation Guide</h2>
                    <div className="steps-grid">
                        <div className="step-card">
                            <div className="step-number">1</div>
                            <h3>Download</h3>
                            <p>Download the latest version of ResourceDecrypt for your platform.</p>
                        </div>
                        <div className="step-card">
                            <div className="step-number">2</div>
                            <h3>Install</h3>
                            <p>Place the .jar file in your Minecraft mods folder.</p>
                        </div>
                        <div className="step-card">
                            <div className="step-number">3</div>
                            <h3>Launch</h3>
                            <p>Start Minecraft with Forge/Fabric and enjoy the tools!</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Download;
