import React, { useState, useEffect, useCallback } from 'react';
import { FaCheckCircle, FaExclamationCircle, FaInfoCircle, FaTimes } from 'react-icons/fa';
import '../styles/NotifyModal.css';

const NotifyModal = ({ type = 'info', message, isVisible, onClose }) => {
    const [isExiting, setIsExiting] = useState(false);

    const handleClose = useCallback(() => {
        setIsExiting(true);
        setTimeout(() => {
            setIsExiting(false);
            onClose();
        }, 300); // Match animation duration
    }, [onClose]);

    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                handleClose();
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [isVisible, handleClose]);

    if (!isVisible) return null;

    const getIcon = () => {
        switch (type) {
            case 'success':
                return <FaCheckCircle />;
            case 'error':
                return <FaExclamationCircle />;
            default:
                return <FaInfoCircle />;
        }
    };

    return (
        <div
            className={`notify-container ${type} ${isExiting ? 'exit' : ''}`}
            role="alert"
        >
            <div className="notify-icon">
                {getIcon()}
            </div>
            <div className="notify-content">
                <p>{message}</p>
            </div>
            <button className="notify-close" onClick={handleClose}>
                <FaTimes />
            </button>
            <div className="notify-progress"></div>
        </div>
    );
};

export default NotifyModal;
