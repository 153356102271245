import React, { useState, useEffect } from 'react';
import { FaUpload, FaShieldAlt, FaTrash, FaFile, FaUnlock, FaKey, FaExclamationTriangle, FaCheckCircle, FaDownload, FaRedo } from 'react-icons/fa';
import NotifyModal from '../components/NotifyModal';
import '../styles/ResourcePack.css';

const ResourcePack = () => {
    const [file, setFile] = useState(null);
    const [isPremium, setIsPremium] = useState(false);
    const [licenseKey, setLicenseKey] = useState('');
    const [licenseVerified, setLicenseVerified] = useState(false);
    const [licenseExpiry, setLicenseExpiry] = useState(null);
    const [error, setError] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressLabel, setProgressLabel] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [uniqueId, setUniqueId] = useState(null);
    const [notifyConfig, setNotifyConfig] = useState({
        show: false,
        type: '',
        message: ''
    });

    const API_BASE_URL = 'https://apibeta.resourcedecrypt.cc/api';
    const MAX_FREE_SIZE = 10 * 1024 * 1024;
    const MAX_PREMIUM_SIZE = 60 * 1024 * 1024;
    const CHUNK_SIZE = 5 * 1024 * 1024;

    useEffect(() => {
        const checkLicenseStatus = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/license/check`, {
                    method: 'GET',
                    credentials: 'include'
                });
                const data = await response.json();
                if (response.ok && data.isValid) {
                    setLicenseKey(data.licenseKey);
                    setLicenseVerified(true);
                    setIsPremium(true);
                    setLicenseExpiry(new Date(data.expiryTime));
                }
            } catch (error) {
                console.log('No active license found on page load:', error.message);
            }
        };
        checkLicenseStatus();
    }, []);

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFile(e.dataTransfer.files[0]);
    };

    const handleFile = (file) => {
        setFile(file);
        const maxSize = isPremium ? MAX_PREMIUM_SIZE : MAX_FREE_SIZE;
        setError(file && file.size > maxSize);
    };

    const handleClear = () => {
        setFile(null);
        setError(false);
        setProgress(0);
        setProgressLabel('');
        setIsUploading(false);
        setIsCompleted(false);
        setUniqueId(null);
    };

    const showNotification = (message, type) => {
        setNotifyConfig({
            show: true,
            type,
            message
        });
    };

    const closeNotification = () => {
        setNotifyConfig({
            show: false,
            type: '',
            message: ''
        });
    };

    const handleVerify = async () => {
        if (!licenseKey) {
            showNotification('Please enter a license key.', 'error');
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/license/activate`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: `licenseKey=${encodeURIComponent(licenseKey)}`,
                credentials: 'include'
            });
            const data = await response.json();
            if (response.ok) {
                setLicenseVerified(true);
                setIsPremium(true);
                setLicenseExpiry(new Date(data.expiryTime));
                showNotification('Premium license activated! 60MB limit enabled.', 'success');
                if (file) {
                    const maxSize = MAX_PREMIUM_SIZE;
                    setError(file.size > maxSize);
                }
            } else {
                setLicenseVerified(false);
                showNotification(data.message, 'error');
            }
        } catch (error) {
            showNotification('Error connecting to license server: ' + error.message, 'error');
        }
    };

    const uploadChunk = (chunk, uploadId, chunkIndex, totalChunks, filename, totalSize, currentUploadedBytes) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', chunk);
            formData.append('uploadId', uploadId || '');
            formData.append('chunkIndex', chunkIndex);
            formData.append('totalChunks', totalChunks);
            formData.append('filename', filename);

            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${API_BASE_URL}/upload`, true);
            xhr.withCredentials = true;

            let uploadedBytesForChunk = currentUploadedBytes;

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const chunkProgress = (event.loaded / event.total) * 100;
                    const chunkSize = chunk.size;
                    const overallProgress = (uploadedBytesForChunk + (chunkProgress / 100) * chunkSize) / totalSize * 100;
                    setProgress(overallProgress);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve({ responseText: xhr.responseText, bytes: chunk.size });
                } else {
                    reject(new Error('Chunk upload failed: ' + xhr.responseText));
                }
            };

            xhr.onerror = () => {
                reject(new Error('Network error during chunk upload.'));
            };

            xhr.send(formData);
        });
    };

    const handleUpload = async () => {
        if (!file || isUploading) return;
        setIsUploading(true);
        setProgress(0);
        setProgressLabel('Uploading...');

        const totalSize = file.size;
        const totalChunks = Math.ceil(totalSize / CHUNK_SIZE);
        let uploadedBytes = 0;
        let uploadId = null;

        try {
            for (let i = 0; i < totalChunks; i++) {
                const start = i * CHUNK_SIZE;
                const end = Math.min(start + CHUNK_SIZE, totalSize);
                const chunk = file.slice(start, end);

                const { responseText, bytes } = await uploadChunk(chunk, uploadId, i, totalChunks, file.name, totalSize, uploadedBytes);

                uploadedBytes += bytes;
                if (i === 0) {
                    uploadId = responseText.split('Upload ID: ')[1]?.trim();
                }

                if (i === totalChunks - 1) {
                    const match = responseText.match(/Unique ID: ([a-f0-9-]+)/i);
                    if (match) {
                        setUniqueId(match[1]);
                        showNotification('File uploaded successfully! Extraction started.', 'success');
                        startExtractionProgress(match[1]);
                    } else {
                        throw new Error('Upload succeeded, but no Unique ID found.');
                    }
                }
            }
        } catch (error) {
            setIsUploading(false);
            showNotification('Error during upload: ' + error.message, 'error');
            setProgressLabel('');
        }
    };

    const startExtractionProgress = (uniqueId) => {
        setProgressLabel('Extracting...');
        setProgress(0);
        const interval = setInterval(async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/progress/${uniqueId}`, {
                    credentials: 'include'
                });
                if (response.ok) {
                    const progress = await response.json();
                    setProgress(progress.progressPercentage);
                    if (progress.completed) {
                        clearInterval(interval);
                        setIsCompleted(true);
                        setIsUploading(false);
                        setProgressLabel('');
                        showNotification('Resource pack successfully processed!', 'success');
                    }
                } else {
                    clearInterval(interval);
                    setIsUploading(false);
                    showNotification('Error checking progress: ' + response.statusText, 'error');
                    setProgressLabel('');
                }
            } catch (error) {
                clearInterval(interval);
                setIsUploading(false);
                showNotification('Error during progress check: ' + error.message, 'error');
                setProgressLabel('');
            }
        }, 500);
    };

    const handleDownload = async () => {
        if (!uniqueId) {
            showNotification('No file available to download.', 'error');
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/download/${uniqueId}/zip`, {
                credentials: 'include'
            });
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = file.name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                showNotification('Download started!', 'success');
            } else {
                showNotification('Error: File not found or processing incomplete.', 'error');
            }
        } catch (error) {
            showNotification('Error during download: ' + error.message, 'error');
        }
    };

    const handleReset = () => {
        setFile(null);
        setError(false);
        setProgress(0);
        setProgressLabel('');
        setIsUploading(false);
        setIsCompleted(false);
        setUniqueId(null);
    };

    return (
        <div className="resource-pack-container">
            <NotifyModal
                type={notifyConfig.type}
                message={notifyConfig.message}
                isVisible={notifyConfig.show}
                onClose={closeNotification}
            />

            <div className="hero">
                <div className="hero-eyebrow2">
                    <span className="status-indicator" />
                    Minecraft Resource Pack Analyzer
                </div>
                <h1 className="gradient-text">Extract Pack</h1>
                <p className="hero-description">
                    Upload your Minecraft resource pack for extraction. Free version supports files up to 10MB, premium extends to 60MB.
                </p>
            </div>

            {!isCompleted ? (
                <div className="content-section">
                    <div className="plan-section">
                        <h2>Select Your Plan</h2>
                        <div className="plan-options">
                            <div
                                className={`plan ${!isPremium && 'active'}`}
                                onClick={() => {
                                    if (!licenseVerified) setIsPremium(false);
                                }}
                                style={{
                                    cursor: licenseVerified ? 'not-allowed' : 'pointer',
                                    opacity: licenseVerified ? 0.5 : 1,
                                }}
                            >
                                <FaUnlock className="plan-icon" />
                                <h2>Free Plan</h2>
                                <h3>Java Version</h3>
                                <p>10MB upload limit</p>
                            </div>
                            <div
                                className={`plan ${isPremium && 'active'}`}
                                onClick={() => {
                                    if (licenseVerified) setIsPremium(true);
                                }}
                                style={{
                                    cursor: licenseVerified ? 'pointer' : 'not-allowed',
                                    opacity: licenseVerified ? 1 : 0.5,
                                }}
                            >
                                <FaShieldAlt className="plan-icon" />
                                <h2>Premium Plan</h2>
                                <h3>Java Version</h3>
                                <h3>Bedrock Version Soon</h3>
                                <p>60MB upload limit</p>
                            </div>
                        </div>
                        <div className="license-input">
                            <div className="input-wrapper">
                                <FaKey className="input-icon" />
                                <input
                                    type="text"
                                    placeholder="Enter license key (optional)"
                                    value={licenseKey}
                                    onChange={(e) => setLicenseKey(e.target.value)}
                                    disabled={licenseVerified}
                                />
                            </div>
                            {!licenseVerified && (
                                <button className="btn btn-primary" onClick={handleVerify}>
                                    Activate
                                </button>
                            )}
                        </div>
                        {licenseVerified && (
                            <p className="license-input">
                                <FaCheckCircle /> Premium license verified (60MB limit enabled until {new Date(licenseExpiry).toLocaleTimeString()})
                            </p>
                        )}
                    </div>

                    <div className="upload-section">
                        <h2>Upload Resource Pack</h2>
                        <div
                            className="upload-area"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleDrop}
                            onClick={() => document.getElementById('fileInput').click()}
                        >
                            <FaUpload className="upload-icon" />
                            <p>Drag & drop resource pack here or click to browse</p>
                            <input
                                type="file"
                                id="fileInput"
                                accept=".zip"
                                onChange={(e) => handleFile(e.target.files[0])}
                                hidden
                            />
                        </div>
                        {file && (
                            <div className="file-info">
                                <FaFile />
                                <span>{file.name}</span>
                                <span>{formatFileSize(file.size)}</span>
                            </div>
                        )}
                        {error && (
                            <p className="file-error">
                                <FaExclamationTriangle /> File exceeds size limit
                            </p>
                        )}
                        {progress > 0 && (
                            <div className="progress-section">
                                <label>{progressLabel}</label>
                                <div className="progress-bar">
                                    <div className="progress" style={{ width: `${progress}%` }}>
                                        <span className="progress-text">{Math.round(progress)}%</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="action-buttons">
                            <button className="btn btn-secondary" onClick={handleClear} disabled={!file || isUploading}>
                                <FaTrash /> Clear
                            </button>
                            <button className="btn btn-primary" onClick={handleUpload} disabled={!file || error || isUploading}>
                                <FaUpload /> Upload
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="content-section completed-section">
                    <div className="thank-you-message">
                        <h2>Thank you for using our service!</h2>
                        <p>Your resource pack has been successfully processed.</p>
                    </div>
                    <div className="action-buttons-vertical">
                        <button className="btn btn-primary download-button" onClick={handleDownload}>
                            <FaDownload /> Download Decompiled Pack
                        </button>
                        <button className="btn btn-primary reset-button" onClick={handleReset}>
                            <FaRedo /> Unpack the next package
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResourcePack;