import React from "react";
import "../styles/Policy.css";

const terms = [
    {
        id: 1,
        title: "What is resourcedecrypt.cc?",
        description:
            "Our site is a tool designed to decompile encrypted ZIP and JAR files, provided 'as is.' We make no guarantees about its performance, reliability, or suitability. The owner is not responsible for any outcomes of its use."
    },
    {
        id: 2,
        title: "Your Responsibilities",
        description:
            "You are solely responsible for the files you upload, their content, legality, and compliance with all applicable laws. You may not use this tool for illegal activities, including violating copyrights, intellectual property rights, or other regulations. The site owner is not liable for any legal violations or damages caused by your actions."
    },
    {
        id: 3,
        title: "File Deletion",
        description:
            "All uploaded files are automatically deleted from our server after 2 hours. We don’t keep copies or use them in any way."
    },
    {
        id: 4,
        title: "Our Role",
        description:
            "We provide this tool as a neutral service and have no involvement in how it is used. We don’t monitor, endorse, or take responsibility for your uploads or actions. The site owner is not liable for any consequences, legal or otherwise, arising from your use of resourcedecrypt.cc."
    },
    {
        id: 5,
        title: "Limitation of Liability",
        description:
            "We don’t guarantee that the tool will work flawlessly, meet your expectations, or be suitable for any purpose. You use it entirely at your own risk. To the fullest extent permitted by law, the site owner is not liable for any damages, losses, legal claims, or consequences resulting from your use of this site, including indirect, incidental, or punitive damages."
    },
    {
        id: 6,
        title: "Changes to These Terms",
        description:
            "We may update these terms at any time. The latest version will always be available on this page."
    },
    {
        id: 7,
        title: "Contact",
        description:
            "Got questions? Reach out to us at [insert contact method, e.g., email, if desired]."
    },
    {
        id: 8,
        title: "Technical Necessities",
        description:
            "We use only the minimal technical processes required to operate the site (e.g., temporary file handling). These are necessary for functionality and not used to track or identify you. The owner is not liable for any issues arising from these processes."
    },
    {
        id: 9,
        title: "Legal Compliance",
        description:
            "You agree to use this tool in accordance with all local, national, and international laws. The site owner does not verify, ensure, or bear responsibility for your compliance with legal standards."
    },
    {
        id: 10,
        title: "Indemnification",
        description:
            "You agree to indemnify and hold the site owner harmless from any claims, damages, or legal actions arising from your use of resourcedecrypt.cc, including any costs or attorney fees incurred by the owner."
    },
    {
        id: 11,
        title: "No Warranty",
        description:
            "This tool is provided without any warranties, express or implied, including warranties of merchantability or fitness for a particular purpose. The owner disclaims all responsibility for its performance or reliability."
    }
];

const TermsOfService = () => {
    return (
        <section className="policy-page">
            <section className="policy-hero">
                <div>
                    <div className="policy-eyebrow">
                        <span className="status-indicator"></span>
                        Rules of Engagement
                    </div>
                    <h1>
                        <span className="gradient-text">Terms of Service</span>
                    </h1>
                    <p className="policy-description">
                        These terms govern your use of resourcedecrypt.cc. Read them to understand how we operate and what we expect from you.
                    </p>
                </div>
            </section>

            <section className="policy-list">
                <div className="policy-container">
                    {terms.map((term) => (
                        <div key={term.id} className="policy-item">
                            <div className="policy-number">{term.id}</div>
                            <div className="policy-content">
                                <h3>{term.title}</h3>
                                <p>{term.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </section>
    );
};

export default TermsOfService;